import { useState, useEffect, useCallback } from "react"
import { useSearchParams, Link } from 'react-router-dom';

import favicon from "../../../assets/mainItems/favicon.svg"
import FmdGoodIcon from '@mui/icons-material/FmdGood';

import './NavBar.css'

const NavBar = ({ restaurantServiceTypeParent }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const fulfillmentMode = searchParams.get('fulfillment_mode') || 'pickup';
  const [navbarStatus, setNavbarStatus] = useState('pickup')

  // Memoize the handler function to avoid re-creation
  const restaurantServiceTypeHandler = useCallback((type) => {
    if (type !== navbarStatus) {
      setNavbarStatus(type);
      restaurantServiceTypeParent(type);
    }
  }, [navbarStatus, restaurantServiceTypeParent]);

  // Push the initial parameters into the complete URL
  useEffect(() => {
    // Run this effect when the component mounts
    restaurantServiceTypeHandler(fulfillmentMode);

    // If no fulfillment_mode is present, set it to "pickup"
    if (!searchParams.get('fulfillment_mode')) {
      setSearchParams({ fulfillment_mode: 'pickup' }, { replace: true });
    }
  }, [restaurantServiceTypeHandler, fulfillmentMode, searchParams, setSearchParams]); // Add the necessary dependencies


  return <div className='navbar-wrapper'>
    <div className="navbar-inner-wrapper">
      <div className="navbar-logo-btn-wrapper">
        <img src={favicon} alt="The Public Market" className="navbar-logo" />
        <h1 className="navbar-title">Flavor District</h1>
      </div>
      <div className="navbar-address-wrapper">
        <div className="navbar-button-wrapper">
          <button
            className={`navbar-setup-btn ${navbarStatus === 'pickup' ? 'navbar-btn-active' : ''}`}
            onClick={() => { restaurantServiceTypeHandler('pickup') }}
          >
            <Link to="/?fulfillment_mode=pickup">Pickup</Link>
          </button>
          <button
            className={`navbar-delivery-btn ${navbarStatus === 'delivery' ? 'navbar-btn-active' : ''}`}
            onClick={() => { restaurantServiceTypeHandler('delivery') }}
          >
            <Link to="/?fulfillment_mode=delivery">Delivery</Link></button>
        </div>
        <div className="navbar-address-information">
          <div className="navbar-location-icon" >
            <FmdGoodIcon />
          </div>
          <div style={{ textAlign: "start" }}>
            11016 Capital Blvd, Wake Forest, NC 27587
          </div>

        </div>
      </div>
    </div>

    <div className="navbar-login-cart-wrapper"></div>
  </div>
}

export default NavBar