export const timeStatus = (
    firstOpen,
    firstClose,
    secondOpen,
    closeOvernight,
    currentTime
) => {
    let restaurantStatus;
    let newTime;

    // eslint-disable-next-line
    if (currentTime.slice(0, 2) == 24) {
        let minutes = currentTime.slice(3, 5);
        newTime = `00:${minutes}`;
    } else {
        newTime = currentTime;
    }

    // if (newTime >= secondOpen && newTime < firstClose) {
    //   restaurantStatus = "Open";
    // } else if (newTime < closeOvernight) {
    //   restaurantStatus = "Open";
    // } else {
    //   restaurantStatus = "Closed";
    // }

    // Check if restaurant opens that day
    if (firstOpen === "00:00" && secondOpen === "00:00") {
        restaurantStatus = "Closed"

        // Open during the day
    } else if (newTime >= firstOpen && newTime <= firstClose) {
        restaurantStatus = "Open"

        // Open during night before day change
    } else if (newTime >= secondOpen && secondOpen !== "00:00") {
        restaurantStatus = "Open"

        // Open after day change
    } else if (newTime <= closeOvernight && secondOpen === "00:00") {
        restaurantStatus = "Open"

        // Open after day change but with 2 business opennings and 1 close
    } else if (newTime <= closeOvernight) {
        restaurantStatus = "Open"
    } else {
        restaurantStatus = "Closed"
    }

    return restaurantStatus;
};

export const getCurrentDay = (timeZone) => {
    //   Current time info
    let currentTime;
    let currentDay;

    // Get current weekday, hour and minute
    const currentInfo = new Date().toLocaleString("en-US", {
        timeZone: timeZone,
        hour12: false,
        hour: "numeric",
        minute: "numeric",
        weekday: "long",
    });

    //   Get current time depending on timezone and split in array
    const parts = currentInfo.split(" ");
    currentDay = parts[0];
    currentTime = parts[1];

    return { currentDay, currentTime };
};
