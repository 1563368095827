import './RestaurantList.css'
import ButtonFormat from './restaurantBtn/ButtonFormat'

import flavorDistrictInfo from "../../data/flavorDistrictData.json"

const RestaurantList = ({ serviceTypeChild }) => {
    return <div className='restaurantlist-wrapper'>
        {flavorDistrictInfo[serviceTypeChild].map((info) => {
            return <ButtonFormat key={info.id} restaurantInformation={info} />
        })}

    </div>
}

export default RestaurantList