import heroImg from "../../assets/mainItems/heroImg.png"

import './HeroImg.css'

const HeroImg = (params) => {
    return <div className="heroimg-wrapper">
        <div className='heroimg-title-wrapper'>
            <h1>Welcome to Flavor District</h1>
            <h2>One spot, multiple options.</h2>
        </div>
        <div className="heroimg-image-wrapper">
            <img src={heroImg} alt="Banner" className="heroimg-image" />
        </div>
    </div>
}

export default HeroImg